import React from "react"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"

const Home = () => {
  return (
    <Layout>
      <Banner />
    </Layout>
  )
}

export default Home
