import React from 'react'
import { RecoilRoot } from 'recoil'
import Seo from './Seo'
import GoTop from './GoTop'

const Layout = ({children}) => {
	return (
		<RecoilRoot>
			<Seo/>
			{children}
			<GoTop scrollStepInPx="100" delayInMs="10.50" />
		</RecoilRoot>
	)
}

export default Layout
