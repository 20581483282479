import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = () => {
	return (
		<div>
			<Helmet>
				<title>Aufsichter - Your Node guardian providing dynamic RBAC</title>
				<meta name="description" content="Aufsichter - Your Node guardian providing dynamic RBAC"/>
			</Helmet>
		</div>
	)
}

export default Seo
