import React, {Fragment, useEffect, useState, useRef} from 'react'

const GoTop = ({scrollStepInPx, delayInMs}) => {
	const [thePosition, setThePosition] = useState(false);
	const timeoutRef = useRef(null)

	useEffect(() => {
		document.addEventListener("scroll", () => {
			if (window.scrollY > 170) {
				setThePosition(true)
			} else {
				setThePosition(false);
			}
		});
	}, [])

	const onScrollStep = () => {
		if (window.scrollY === 0){
			clearInterval(timeoutRef.current);
		}
		window.scroll(0, window.scrollY - scrollStepInPx);
	}

	const scrollToTop = () => {
		timeoutRef.current = setInterval(onScrollStep, delayInMs);
	}

	const renderGoTopIcon = () => {
		return (
			<div
				className={`go-top ${thePosition ? 'active' : ''}`}
				onClick={scrollToTop}
				role="button"
				tabIndex="0"
				aria-hidden="true"
			>
				<i className="flaticon-up"/>
			</div>
		)
	}

	return (
		<Fragment>
			{renderGoTopIcon()}
		</Fragment>
	)
}

export default GoTop
